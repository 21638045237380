var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        hidden: !this.visible,
        'on-focus': _vm.truck.on_focus || _vm.truck.on_activity,
        collapsed: _vm.collapsed
    },on:{"click":function($event){return _vm.$emit('click', _vm.truck)},"contextmenu":function($event){$event.preventDefault();return _vm.$emit('contextmenu', _vm.truck)}}},[_vm._t("default"),(_vm.collapsed)?_c('div',{},[_c('div',{staticClass:"truck-header--collapsed",staticStyle:{"display":"flex","justify-content":"center","align-items":"center","flex-direction":"column","min-height":"65px"},attrs:{"id":_vm.truck.plate}},[_c('div',{staticClass:"circle-icon",style:({
                    'background-color': _vm.iconBackgroundColor,
                    'background-image': 'none',
                    position: 'relative'
                })},[(
                        !_vm.config.iconType ||
                        (_vm.config.iconType && _vm.config.iconType.value == 'truck')
                    )?_c('truck-icon',{staticStyle:{"padding":"5px","color":"#fff"},attrs:{"category":_vm.truck.category}}):_c('i',{staticClass:"driver-activity-icon",class:_vm.driverActivityIcon})],1),_c('span',{staticClass:"plate-collapsed"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.identification,7)))]),_c('div',{staticClass:"pin-icon",class:_vm.truck.pinned ? 'pinned' : '',on:{"click":function($event){$event.stopPropagation();return _vm.$emit('pinTruck', _vm.truck)}}},[_c('i',{staticClass:"fa-solid fa-thumbtack"})])]),_c('b-popover',{attrs:{"target":_vm.truck.plate,"triggers":"hover","placement":"left","customClass":"truck-popover","container":"main-panel"}},[_c('ul',{staticClass:"list-inline d-flex justify-content-between mb-0"},[_c('li',{staticClass:"list-inline-item"},[(_vm.truck.lastpoint.rfid)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fa-solid fa-id-badge",style:(_vm.truck.lastpoint.rfid.report_id == 'authorized'
                                ? 'color: var(--rest-color)'
                                : 'color: var(--driving-color)'),attrs:{"container":_vm.truck.id,"title":_vm.__(_vm.truck.lastpoint.rfid.report_id)}}):(_vm.truck.lastpoint.activity === 0)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fa-solid fa-bed",staticStyle:{"color":"var(--rest-color)"},attrs:{"container":_vm.truck.id,"title":_vm.__('driver_at_rest')}}):(_vm.truck.lastpoint.activity == 1)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fak fa-availability",staticStyle:{"color":"var(--availability-color)"},attrs:{"container":_vm.truck.id,"title":_vm.__('driver_available')}}):(_vm.truck.lastpoint.activity == 2)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fak fa-work",staticStyle:{"color":"var(--work-color)"},attrs:{"container":_vm.truck.id,"title":_vm.__('driver_at_work')}}):(_vm.truck.lastpoint.activity == 3)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fa-solid fa-steering-wheel",staticStyle:{"color":"var(--driving-color)"},attrs:{"container":_vm.truck.id,"title":_vm.__('driver_driving')}}):_vm._e(),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fa-solid fa-truck gray-color",attrs:{"container":_vm.truck.id,"title":_vm.__('inactive_vehicle')}})]),_c('li',{staticClass:"list-inline-item"},[_c('i',{class:{
                            'fa-solid fa-user-group': true,
                            'gray-color': !_vm.truck.lastpoint.codriver,
                            'green-color': _vm.truck.lastpoint.codriver
                        }})]),_c('li',{staticClass:"list-inline-item"},[(_vm.vehicleAlertsList.length > 1)?_c('div',{attrs:{"id":`truck_list_items_${_vm.truck.id}`}},[_c('i',{staticClass:"fa-solid fa-triangle-exclamation red-color"}),_vm._v(" "+_vm._s(_vm.vehicleAlertsList.length)+" ")]):_vm._e(),(_vm.vehicleAlertsList.length == 1)?_c('div',[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left.hover",modifiers:{"left":true,"hover":true}}],class:_vm.vehicleAlertsList[0].icon + ' red-color',attrs:{"container":_vm.truck.id,"title":_vm.__(_vm.vehicleAlertsList[0].problem)}})]):_vm._e(),(_vm.vehicleAlertsList.length == 0)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fa-solid fa-triangle-exclamation gray-color",attrs:{"container":_vm.truck.id,"title":_vm.__('no_warning')}}):_vm._e(),(_vm.vehicleAlertsList.length > 1)?_c('b-tooltip',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"tooltip-alerts",attrs:{"target":`truck_list_items_${_vm.truck.id}`,"triggers":"hover","placement":"left"}},_vm._l((_vm.vehicleAlertsList),function(alert){return _c('div',{key:alert.id},[_c('i',{class:alert.icon}),_vm._v(" "+_vm._s(_vm.__(alert.problem))+" ")])}),0):_vm._e()],1)])])],1):_vm._e(),(!_vm.collapsed)?_c('div',{staticClass:"main-cnt"},[_c('div',{staticClass:"conatiner section-veichle",class:_vm.$mq !== 'xs' && _vm.$mq !== 'sm' ? 'col-10' : 'col-12'},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2",attrs:{"align":"center"}},[_c('div',{staticClass:"circle-icon",style:({
                            'background-color': _vm.iconBackgroundColor,
                            'background-image': 'none',
                            position: 'relative'
                        })},[(
                                !_vm.config.iconType ||
                                (_vm.config.iconType && _vm.config.iconType.value == 'truck')
                            )?_c('truck-icon',{staticStyle:{"padding":"5px","color":"#fff"},attrs:{"category":_vm.truck.category}}):_c('i',{staticClass:"driver-activity-icon",class:_vm.driverActivityIcon}),(_vm.lock_command)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.right.hover",modifiers:{"right":true,"hover":true}}],staticClass:"with-commands",attrs:{"title":_vm.__('vehicle_with_lock_commands')}},[_c('i',{staticClass:"fa-solid fa-lock fa-2xs"})]):_vm._e()],1),(_vm.is_truck_hooked(_vm.truck.plate))?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.html",modifiers:{"hover":true,"html":true}}],staticClass:"chain-icon",class:_vm.truck.pinned ? 'hooked' : '',attrs:{"title":_vm.__('vehicle_hooked')},on:{"click":function($event){$event.stopPropagation();_vm.$emit('detailTruckHooked', _vm.get_vehicle_hooked(_vm.truck.plate))}}},[_c('i',{staticClass:"fa-regular fa-link-horizontal"})]):_vm._e(),_c('div',{staticClass:"pin-icon",class:_vm.truck.pinned ? 'pinned' : '',on:{"click":function($event){$event.stopPropagation();return _vm.$emit('pinTruck', _vm.truck)}}},[_c('i',{staticClass:"fa-solid fa-thumbtack"})])]),(!_vm.hideInfo)?_c('div',{staticClass:"col-10 info-detail"},[_c('div',{staticClass:"conatiner"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('span',{staticClass:"plate-full",class:[
                                        _vm.config?.vehicleIdentification,
                                        _vm.identification.length > 10 ? 'long-text' : ''
                                    ]},[_vm._v(_vm._s(_vm.identification))])]),_c('div',{staticClass:"col"},[_c('ul',{staticClass:"list-inline d-flex justify-content-between"},[_c('li',{staticClass:"list-inline-item"},[(_vm.truck.lastpoint.rfid)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fa-solid fa-id-badge",style:(_vm.truck.lastpoint.rfid.report_id == 'authorized'
                                                    ? 'color: var(--rest-color)'
                                                    : 'color: var(--driving-color)'),attrs:{"container":_vm.truck.id,"title":_vm.__(_vm.truck.lastpoint.rfid.report_id)}}):(_vm.truck.lastpoint.activity === 0)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fa-solid fa-bed",staticStyle:{"color":"var(--rest-color)"},attrs:{"container":_vm.truck.id,"title":_vm.__('driver_at_rest')}}):(_vm.truck.lastpoint.activity == 1)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fak fa-availability",staticStyle:{"color":"var(--availability-color)"},attrs:{"container":_vm.truck.id,"title":_vm.__('driver_available')}}):(_vm.truck.lastpoint.activity == 2)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fak fa-work",staticStyle:{"color":"var(--work-color)"},attrs:{"container":_vm.truck.id,"title":_vm.__('driver_at_work')}}):(_vm.truck.lastpoint.activity == 3)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fa-solid fa-steering-wheel",staticStyle:{"color":"var(--driving-color)"},attrs:{"container":_vm.truck.id,"title":_vm.__('driver_driving')}}):_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fa-solid fa-truck gray-color",attrs:{"container":_vm.truck.id,"title":_vm.__('inactive_vehicle')}})]),_c('li',{staticClass:"list-inline-item"},[_c('i',{class:{
                                                'fa-solid fa-user-group': true,
                                                'gray-color': !_vm.truck.lastpoint.codriver,
                                                'green-color': _vm.truck.lastpoint.codriver
                                            }})]),_c('li',{staticClass:"list-inline-item"},[(_vm.vehicleAlertsList.length > 1)?_c('div',{attrs:{"id":`truck_list_items_${_vm.truck.id}`}},[_c('i',{staticClass:"fa-solid fa-triangle-exclamation red-color"}),_vm._v(" "+_vm._s(_vm.vehicleAlertsList.length)+" ")]):_vm._e(),(_vm.vehicleAlertsList.length == 1)?_c('div',[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left.hover",modifiers:{"left":true,"hover":true}}],class:_vm.vehicleAlertsList[0].icon + ' red-color',attrs:{"container":_vm.truck.id,"title":_vm.__(_vm.vehicleAlertsList[0].problem)}})]):_vm._e(),(_vm.vehicleAlertsList.length == 0)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fa-solid fa-triangle-exclamation gray-color",attrs:{"container":_vm.truck.id,"title":_vm.__('no_warning')}}):_vm._e(),(_vm.vehicleAlertsList.length > 1)?_c('b-tooltip',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"tooltip-alerts",attrs:{"target":`truck_list_items_${_vm.truck.id}`,"triggers":"hover","placement":"left"}},_vm._l((_vm.vehicleAlertsList),function(alert){return _c('div',{key:alert.id},[_c('i',{class:alert.icon}),_vm._v(" "+_vm._s(_vm.__(alert.problem))+" ")])}),0):_vm._e()],1)])])]),(
                                (_vm.config?.vehicleIdentification == 'customId' &&
                                    _vm.truck.details.custom_id) ||
                                _vm.config?.vehicleIdentification == 'driver' ||
                                (!_vm.config?.vehicleIdentification && _vm.truck.details.custom_id)
                            )?_c('div',{staticClass:"row"},[_c('small',{staticClass:"text-muted my-1 text-dark-blue"},[_c('i',{staticClass:"fa-solid fa-tag"}),_vm._v(" "+_vm._s(_vm.truck.plate))])]):_vm._e(),(
                                (_vm.config?.truckListItemFields &&
                                    _vm.config?.truckListItemFields.includes('address')) ||
                                !_vm.config?.truckListItemFields
                            )?_c('div',{staticClass:"row"},[_c('span',{staticClass:"localizzazione-f reverse-geocoded"},[_c('reverse-geocoding',{attrs:{"latLon":[_vm.truck.lastpoint.lat, _vm.truck.lastpoint.lng],"truncated":70}})],1)]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"d-flex flex-row justify-content-start mx-auto mt-25"},[(
                                    (_vm.config?.truckListItemFields &&
                                        _vm.config?.truckListItemFields.includes('speed')) ||
                                    !_vm.config?.truckListItemFields
                                )?_c('div',{staticClass:"pr-4 text-center"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.html",modifiers:{"hover":true,"html":true}}],staticClass:"localizzazione-f",attrs:{"title":_vm.truck.lastpoint.tacho_speed &&
                                        _vm.config?.truckListItemFields &&
                                        !_vm.config?.truckListItemFields.includes('tacho_speed')
                                            ? _vm.__('speed') +
                                              ' ' +
                                              _vm.truck.lastpoint.speed +
                                              ' km/h <br> ' +
                                              _vm.__('tacho_speed') +
                                              ' ' +
                                              _vm.truck.lastpoint.tacho_speed +
                                              ' km/h'
                                            : _vm.__('speed') + ' ' + _vm.truckSpeed + ' km/h'}},[_c('i',{staticClass:"fa-solid fa-gauge"}),_vm._v(" "+_vm._s(_vm.truckSpeed)+" km/h")])]):_vm._e(),(
                                    _vm.config?.truckListItemFields &&
                                    _vm.config?.truckListItemFields.includes('tacho_speed') &&
                                    _vm.truck.lastpoint.tacho_speed
                                )?_c('div',{staticClass:"pl-4 pr-4 text-center left-bar"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"localizzazione-f",attrs:{"title":_vm.__('tacho_speed') +
                                        ' ' +
                                        _vm.truck.lastpoint.tacho_speed +
                                        ' km/h'}},[_c('i',{staticClass:"fa-solid fa-gauge"}),_vm._v(" "+_vm._s(_vm.truck.lastpoint.tacho_speed)+" km/h")])]):_vm._e(),(
                                    _vm.config?.truckListItemFields &&
                                    _vm.config?.truckListItemFields.includes('heading') &&
                                    _vm.truck.lastpoint.heading
                                )?_c('div',{staticClass:"pl-4 pr-4 text-center left-bar"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"localizzazione-f",attrs:{"title":_vm.__('heading') + ' ' + _vm.truck.lastpoint.heading + '°'}},[_c('i',{staticClass:"fa-solid fa-arrow-up",style:(`transform: rotate(${_vm.truck.lastpoint.heading}deg)`)}),_vm._v(" "+_vm._s(_vm.truck.lastpoint.heading)+"°")])]):_vm._e(),(
                                    ((_vm.config?.truckListItemFields &&
                                        (_vm.config?.truckListItemFields.includes('fuel_level') ||
                                            _vm.config?.truckListItemFields.includes(
                                                'fuel_battery_level'
                                            ))) ||
                                        !_vm.config?.truckListItemFields) &&
                                    _vm.truck.lastpoint.fuel_level
                                )?_c('div',{staticClass:"pl-4 pr-4 text-center left-bar"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"localizzazione-f",attrs:{"title":_vm.__('fuel') + ' ' + _vm.truck.lastpoint.fuel_level + '%'}},[_c('i',{staticClass:"fas fa-gas-pump"}),_vm._v(" "+_vm._s(_vm.truck.lastpoint.fuel_level)+"%")])]):_vm._e(),(
                                    ((_vm.config?.truckListItemFields &&
                                        (_vm.config?.truckListItemFields.includes(
                                            'battery_level'
                                        ) ||
                                            _vm.config?.truckListItemFields.includes(
                                                'fuel_battery_level'
                                            ))) ||
                                        !_vm.config?.truckListItemFields) &&
                                    _vm.truck.lastpoint?.telemetry?.battery_level &&
                                    !_vm.truck.lastpoint.fuel_level
                                )?_c('div',{staticClass:"pl-4 pr-4 text-center left-bar"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"localizzazione-f",attrs:{"title":_vm.__('battery') +
                                        ' ' +
                                        (_vm.truck.lastpoint?.telemetry?.battery_level || 0) +
                                        '%'}},[_c('i',{staticClass:"fas fa-battery"}),_vm._v(" "+_vm._s(_vm.truck.lastpoint?.telemetry?.battery_level || 0)+"%")])]):_vm._e(),(
                                    ((_vm.config?.truckListItemFields &&
                                        _vm.config?.truckListItemFields.includes('altitude')) ||
                                        !_vm.config?.truckListItemFields) &&
                                    _vm.truck.lastpoint.altitude !== null
                                )?_c('div',{staticClass:"pl-4 pr-4 text-center left-bar"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"localizzazione-f",attrs:{"title":_vm.__('altitude') + ' ' + _vm.truck.lastpoint.altitude + ' m'}},[_c('i',{staticClass:"fas fa-mountain"}),_vm._v(" "+_vm._s(_vm.truck.lastpoint.altitude)+" m")])]):_vm._e(),(
                                    _vm.config?.truckListItemFields &&
                                    _vm.config?.truckListItemFields.includes('rpm_engine') &&
                                    _vm.truck.lastpoint.rpm_engine
                                )?_c('div',{staticClass:"pl-4 pr-4 text-center left-bar"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"localizzazione-f",attrs:{"title":_vm.__('rpm_engine') + ' ' + _vm.truck.lastpoint.rpm_engine}},[_c('i',{staticClass:"fas fa-tachometer-alt"}),_vm._v(" "+_vm._s(_vm.truck.lastpoint.rpm_engine)+" rpm")])]):_vm._e(),(
                                    (_vm.config?.truckListItemFields &&
                                        _vm.config?.truckListItemFields?.includes(
                                            'daily_distance'
                                        ) &&
                                        (_vm.averageData?.distance?.toFixed(0) ||
                                            _vm.averageData?.gps_distance?.toFixed(0))) ||
                                    (_vm.config?.truckListItemFields &&
                                        _vm.config?.truckListItemFields?.includes(
                                            'daily_distance'
                                        ) &&
                                        _vm.truck?.lastpoint?.daily_distance)
                                )?_c('div',{staticClass:"pl-4 pr-4 text-center left-bar"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"localizzazione-f",attrs:{"title":_vm.__('daily_distance') +
                                        ' ' +
                                        (_vm.averageData?.distance?.toFixed(0) ||
                                            _vm.averageData?.gps_distance?.toFixed(0))}},[_c('i',{staticClass:"fa-solid fa-route"}),_vm._v(" "+_vm._s(_vm.averageData?.distance?.toFixed(0) || _vm.averageData?.gps_distance?.toFixed(0) || _vm.truck?.lastpoint?.daily_distance)+" Km")])]):_vm._e(),(
                                    _vm.config?.truckListItemFields &&
                                    _vm.config?.truckListItemFields.includes('gnss_status') &&
                                    _vm.truck.lastpoint?.telemetry?.gnss_status
                                )?_c('div',{staticClass:"pl-4 pr-4 text-center left-bar"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"localizzazione-f",attrs:{"title":_vm.__(
                                            'gnss_status_values.' +
                                                _vm.truck.lastpoint?.telemetry?.gnss_status
                                        )}},[_c('i',{staticClass:"fa-solid fa-satellite-dish"}),_vm._v(" "+_vm._s(_vm.parseGNSSStatus(_vm.truck.lastpoint?.telemetry?.gnss_status)))])]):_vm._e(),(
                                    _vm.config?.truckListItemFields &&
                                    _vm.config?.truckListItemFields.includes('gsm_signal') &&
                                    _vm.truck.lastpoint?.telemetry?.gsm_signal
                                )?_c('div',{staticClass:"pl-4 pr-4 text-center left-bar"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"localizzazione-f",attrs:{"title":_vm.__('gsm_signal') +
                                        ' ' +
                                        _vm.truck.lastpoint?.telemetry?.gsm_signal}},[_c('i',{staticClass:"fa-solid",class:'fa-' +
                                            _vm.parseGSMSignal(
                                                _vm.truck.lastpoint?.telemetry?.gsm_signal
                                            ).icon}),_vm._v(" "+_vm._s(_vm.parseGSMSignal(_vm.truck.lastpoint?.telemetry?.gsm_signal) .value))])]):_vm._e()])])]):_vm._e()])]),(_vm.$mq !== 'xs' && _vm.$mq !== 'sm')?_c('div',{staticClass:"col-2 action-menu"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left.hover",modifiers:{"left":true,"hover":true}}],staticClass:"circle-status btn-plananal",attrs:{"title":_vm.__('analyze')},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('analyze', _vm.truck)}}},[_c('i',{staticClass:"fa-solid fa-chart-mixed"})]),(_vm.$can('use', 'planner'))?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left.hover",modifiers:{"left":true,"hover":true}}],staticClass:"circle-status btn-plananal",attrs:{"title":_vm.__('plan')},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('plan', _vm.truck)}}},[_c('i',{staticClass:"fa-solid fa-route"})]):_vm._e()]):_vm._e()]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }