<template>
    <side-panel>
        <template #head>
            <div class="a-header">
                <h2 class="pt-3">{{ __('manage_poi.title') }}</h2>

                <div class="close-btn" @click="$emit('toggleUtilityPanel')">
                    <i class="fa-solid fa-xmark"></i>
                </div>
            </div>
        </template>

        <template #body>
            <div v-resized="resized" class="d-flex flex-row h-100">
                <div class="map-section">
                    <b-alert
                        class="select-on-map"
                        variant="info"
                        dismissible
                        fade
                        show
                        v-if="singlePoi.address && (!singlePoi.lat || !singlePoi.lng)"
                    >
                        {{ __('planner.select_on_map') }}
                    </b-alert>

                    <geofleet-map
                        class="geofleet-map smaller-controls"
                        map="silica"
                        :advanced="true"
                        :cluster="true"
                        :ptvToken="$env.ptv_password"
                        :extraControls="{}"
                        @readyMap="clickOnMapHandler"
                        ref="map"
                    >
                        <!-- POI Geofencing area -->
                        <template v-if="mode == 'list'">
                            <l-circle
                                v-for="(poi, index) in localPois"
                                :key="index"
                                :lat-lng="poi.latLng"
                                :radius="poi.radius"
                                :color="poiColor(poi)"
                                @click="handleFocusPoi(poi)"
                            >
                            </l-circle>
                        </template>

                        <!-- POI list markers -->
                        <template v-if="mode == 'list'">
                            <custom-marker
                                v-for="(poi, index) in localPois"
                                :key="'poi-' + index"
                                :item="poi"
                                :coordinates="[poi.lat, poi.lng]"
                                :markerTitle="poi.description"
                                :iconUnicode="poi.type_icon ? poiIconUnicode(poi) : '#xf3c5'"
                                @click="handleFocusPoi(poi)"
                                :customizer="{
                                    tiny: true,
                                    color_class: poiClass(poi),
                                    custom_color: poiColor(poi)
                                }"
                            />
                        </template>

                        <!-- Goefencing and markers of selected POI (or new one) -->
                        <template v-if="mode != 'list' && singlePoi.lat && singlePoi.lng">
                            <l-circle
                                v-if="singlePoi.radius"
                                :lat-lng="[singlePoi.lat, singlePoi.lng]"
                                :radius="singlePoi.radius"
                                :color="poiColor(singlePoi)"
                            >
                            </l-circle>

                            <custom-marker
                                :key="mode + '-poi-' + singlePoi.id"
                                :item="singlePoi"
                                :coordinates="[singlePoi.lat, singlePoi.lng]"
                                :markerTitle="singlePoi.description"
                                :iconUnicode="
                                    singlePoi.type_icon ? poiIconUnicode(singlePoi) : '#xf3c5'
                                "
                                :draggable="true"
                                @updateByDrag="movePoiCoordinates"
                                :customizer="{
                                    tiny: true,
                                    color_class: 'poi-manager-marker',
                                    custom_color: poiColor(singlePoi),
                                    size_x: !singlePoi.type_icon ? 38 : null
                                }"
                            />
                        </template>
                    </geofleet-map>
                </div>

                <div class="pois-list-wrapper">
                    <div class="d-flex align-items-center justify-content-between gap-2 mb-3">
                        <golia-input
                            v-if="mode == 'list'"
                            v-model="searchPoi"
                            style="min-width: 20px"
                            :placeholder="__('cerca')"
                        ></golia-input>

                        <golia-input
                            v-if="mode == 'list'"
                            id="type"
                            type="select"
                            :options="poiTypes"
                            v-model="poiTypesSelected"
                            style="min-width: 20px; max-width: 100px"
                        ></golia-input>

                        <golia-button
                            variant="btn-success btn-add-poi"
                            icon="fa-fw fa-plus"
                            :label="__('manage_poi.add_poi')"
                            @click="mode = 'create'"
                            v-show="mode == 'list'"
                        />
                        <golia-button
                            variant="btn-fill dark-blue"
                            icon="fa-fw fa-arrow-left"
                            style="margin-right: 5px"
                            :label="__('go_back')"
                            @click="mode = 'list'"
                            v-show="mode != 'list'"
                        />
                    </div>

                    <!-- Lista POI -->
                    <template v-if="mode == 'list'">
                        <div class="pois-list custom-scrollbar" v-if="localPoisComputed.length > 0">
                            <div
                                class="poi-list-item"
                                :id="poi.id"
                                :class="{ 'focused-poi': poi.on_focus }"
                                v-for="(poi, index) in localPoisComputed"
                                :key="'poi-' + index"
                                @click="handleFocusPoi(poi)"
                            >
                                <golia-list-item :style="{ '--item-border-color': poiColor(poi) }">
                                    <template #extra-contents>
                                        <h3 class="item-title">{{ poi.description }}</h3>
                                        <p class="item-subtitle">
                                            <i class="poi-icon" v-html="poi.type_icon"></i>
                                            {{ getTranslatedDescription(poi) }}
                                        </p>

                                        <small class="text-muted" style="color: #666 !important">
                                            <i class="fa-fw fa-solid fa-location-dot"></i>
                                            <reverse-geocoding
                                                :latLon="poi.latLng"
                                                v-if="poi.latLng"
                                            />
                                        </small>
                                    </template>
                                    <template #actions>
                                        <golia-button
                                            variant="btn-info btn-sm"
                                            icon="fa-fw fa-pencil"
                                            @click="showUpdatePoi(poi)"
                                        />
                                        <golia-button
                                            variant="btn-danger btn-sm"
                                            icon="fa-fw fa-trash"
                                            @click="handleDeletePoi(poi)"
                                        />
                                    </template>
                                </golia-list-item>
                            </div>
                        </div>
                        <div v-else>
                            <div
                                class="no-pois d-flex align-items-center justify-content-center h-100 bg-main-gray"
                            >
                                <div class="text-center mt-3">
                                    <i
                                        class="fa-solid fa-info-circle fa-2x text-muted text-dark-blue"
                                    ></i>
                                    <p class="mt-3 text-muted">{{ __('manage_poi.no_pois') }}</p>
                                </div>
                            </div>
                        </div>
                    </template>

                    <!-- Creazione / Modifica POI -->
                    <template v-else>
                        <div class="form-container">
                            <poi-form
                                v-model="singlePoi"
                                @store="handleCreatePoi"
                                @update="handleUpdatePoi"
                                @searchAddress="centerMap"
                            />
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </side-panel>
</template>

<script>
import SidePanel from './SidePanel.vue'
import PoiForm from './PoiManagerPanel/PoiForm.vue'
import { LCircle } from 'vue2-leaflet'
import L from 'leaflet'

import { mapMutations, mapActions, mapState } from 'vuex'

export default {
    name: 'PoiManagerPanel',
    components: {
        SidePanel,
        LCircle,
        PoiForm
    },
    data() {
        return {
            poiTypes: [
                { value: '', text: this.__('manage_poi.types_poi.all') },
                { value: 1, text: this.__('manage_poi.types_poi.headquarter') },
                { value: 15, text: this.__('manage_poi.types_poi.truck_washes') },
                { value: 12, text: this.__('manage_poi.types_poi.blacklist') },
                { value: 11, text: this.__('manage_poi.types_poi.end_of_the_line') },
                { value: 5, text: this.__('manage_poi.types_poi.customers') },
                { value: 10, text: this.__('manage_poi.types_poi.stops') },
                { value: 14, text: this.__('manage_poi.types_poi.suppliers') },
                { value: 8, text: this.__('manage_poi.types_poi.interports') },
                { value: 9, text: this.__('manage_poi.types_poi.markets') },
                { value: 13, text: this.__('manage_poi.types_poi.large_square') },
                { value: 2, text: this.__('manage_poi.types_poi.poi_distributors') },
                { value: 4, text: this.__('manage_poi.types_poi.poi_workshops') },
                { value: 6, text: this.__('manage_poi.types_poi.harbors') },
                { value: 3, text: this.__('manage_poi.types_poi.restaurants') },
                { value: 7, text: this.__('manage_poi.types_poi.stations') }
            ],
            poiTypesSelected: '',
            searchPoi: '',
            mode: 'list',
            localPois: [],
            singlePoi: {
                id: null,
                description: null,
                address: null,
                address_from_source: null,
                lat: null,
                lng: null,
                radius: null,
                type: 1,
                note: null,
                color: null
            }
        }
    },
    created() {
        this.localPois = this.pois
    },
    methods: {
        handleCreatePoi(poi) {
            this.createPoi({ vueInstance: this, item: poi })

            this.mode = 'list'
        },
        handleUpdatePoi(poi) {
            this.updatePoi({ vueInstance: this, item: poi })

            this.mode = 'list'
        },
        handleDeletePoi(poi) {
            this.deletePoi({ vueInstance: this, item: poi })
        },
        resized() {
            if (this.$refs.map && this.$refs.map.lmap) this.$refs.map.lmap.invalidateSize(true)
        },
        poiColor(poi) {
            return poi.color || poi.type_color || '#000'
        },
        poiClass(poi) {
            return 'poi-manager-marker ' + poi?.type_description?.toLowerCase()?.replace(' ', '-')
        },
        poiIconUnicode(poi) {
            return poi.type_icon.replace('&', '').replace(';', '')
        },
        getTranslatedDescription(poi) {
            const poiToFind = this.poiTypes.find((poi) => poi.value == poi.type)
            const poiLabel = poiToFind ? poiToFind.text : poi.type_description

            return poiLabel || ''
        },
        showUpdatePoi(poi) {
            this.mode = 'update'
            this.singlePoi = poi
        },
        resetSinglePoi() {
            this.singlePoi = {
                id: null,
                description: null,
                address: null,
                lat: null,
                lng: null,
                radius: null,
                type: 1,
                note: null,
                color: null
            }
        },
        centerMap(latLng) {
            this.$refs.map.lmap.setView(latLng, 16)
        },
        fitPoisBounds() {
            if (this.$refs.map && this.$refs.map.lmap && this.pois.length > 0) {
                const bounds = L.latLngBounds(this.pois.map((poi) => poi.latLng))
                this.$refs.map.lmap.fitBounds(bounds, { padding: [20, 20] })
            }
        },
        clickOnMapHandler() {
            this.$refs.map.$refs.map.mapObject.on('click', (e) => {
                var coord = e.latlng
                var lat = coord.lat
                var lng = coord.lng

                if (this.mode != 'list') {
                    this.singlePoi.lat = lat
                    this.singlePoi.lng = lng
                }
            })
        },
        movePoiCoordinates(latLng) {
            this.singlePoi.lat = latLng[0]
            this.singlePoi.lng = latLng[1]
        },
        handleFocusPoi(poi) {
            this.scrollToElement({ scrollList: '.pois-list.custom-scrollbar', element: poi.id })

            this.focusPoi(poi)

            this.$refs.map.lmap.setView(poi.latLng, 16)
        },
        ...mapMutations({
            updatePoi: 'updatePoi',
            focusPoi: 'focusPoi',
            removePoi: 'removePoi'
        }),
        ...mapActions({
            scrollToElement: 'scrollToElement',
            loadPois: 'loadPois',
            createPoi: 'createPoi',
            updatePoi: 'updatePoi',
            deletePoi: 'deletePoi'
        })
    },
    computed: {
        localPoisComputed() {
            let localPois = this.pois.filter((poi) => {
                if (this.searchPoi && this.poiTypesSelected) {
                    return (
                        poi.description.toLowerCase().includes(this.searchPoi.toLowerCase()) &&
                        poi.type == this.poiTypesSelected
                    )
                } else if (this.searchPoi) {
                    return poi.description.toLowerCase().includes(this.searchPoi.toLowerCase())
                } else if (this.poiTypesSelected) {
                    return poi.type == this.poiTypesSelected
                } else {
                    return this.pois
                }
            })
            return localPois
        },
        ...mapState({
            pois: (state) => state.geoFleet.pois
        })
    },
    watch: {
        pois: {
            handler() {
                this.localPois = this.pois
            },
            deep: true
        },
        mode(val) {
            if (val != 'update') this.resetSinglePoi()
        },
        'singlePoi.lat': {
            handler(val) {
                if (val && this.$refs.map && this.$refs.map.lmap) {
                    this.$refs.map.lmap.setView([val, this.singlePoi.lng], 16)
                }
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.fitPoisBounds()
        })
    }
}
</script>

<style scoped lang="scss">
.btn-add-poi {
    margin-right: 5px;
    text-wrap: nowrap;
}
.activity-panel ::v-deep(.head) {
    flex-basis: 50px;
}
.map-section {
    padding: 8px;
    height: 100%;
    flex-basis: 70%;

    display: flex;
    flex-direction: column;

    position: relative;

    .select-on-map {
        position: absolute;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000;

        ::v-deep(button) {
            background-color: transparent;
            border: 0px;
        }
    }
}
.geofleet-map {
    border-radius: 25px;
}
.pois-list-wrapper {
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    margin: 8px 12px 8px 8px;
    position: relative;

    .pois-list.custom-scrollbar {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 5px;
    }

    .poi-list-item {
        cursor: pointer;

        &.focused-poi .golia-list-item {
            background-color: var(--main-focus);
        }

        .poi-icon {
            font-family: 'Font Awesome Kit';
            font-style: initial;
        }

        ::v-deep(.golia-list-item) {
            border-left: 5px solid var(--item-border-color, #fff);
        }
    }
}

.form-container {
    border-radius: 25px;
    padding: 15px;
    background-color: var(--main-inner-bg-color);
}

.no-pois {
    border-radius: 25px;
}
</style>
