<template>
    <div>
        <!-- VEHICLE LIST MODE VIEW -->
        <h5 class="section-title mt-4 mb-2">{{ __('bind_colors') }}</h5>

        <div class="bg-main-inner-bg p-3 rounded-3 status-colors">
            <template v-if="(index, statusList) && statusList.length > 0">
                <div
                    class="form-group d-flex align-items-center"
                    :class="{ 'mb-3': index !== statusList.length - 1 }"
                    v-for="(status, index) in statusList"
                    :key="index"
                >
                    <input
                        class="form-control me-3"
                        type="color"
                        v-model="driversStatus.colors[status.ID]"
                    />
                    <span class="text-smaller">{{ status.DESCRIZIONE }}</span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'DriversStatusSettings',
    props: {
        value: {
            type: Object
        }
    },
    data() {
        return {
            driversStatus: this.value,
            statusList: []
        }
    },
    created() {
        this.getDriverStatus()
    },
    methods: {
        async getDriverStatus() {
            try {
                const response = await this.$fetch(this.$env.GOLIA_API + '/drivers/status')

                if (!response.ok) throw Error(response.statusText)

                let driverStatusResponse = await response.json()

                this.statusList = driverStatusResponse.data

                // for each driversStatus.colors element (is an Object) create the property if not exists with the "ID" of the status
                this.statusList.forEach((status) => {
                    let activeColor = this.config?.driversStatusColors
                        ? this.config?.driversStatusColors[status.ID]
                        : null

                    if (!this.driversStatus.colors[status.ID]) {
                        this.$set(this.driversStatus.colors, status.ID, activeColor)
                    }
                })
            } catch (error) {
                console.log(error)
            }
        }
    },
    watch: {
        value: {
            handler: function (newVal) {
                this.driversStatus = newVal

                this.$emit('saveOption', 'driversStatusColors', newVal.colors)
            },
            deep: true
        },
        driversStatus: {
            handler: function (newVal) {
                this.$emit('input', newVal)
            },
            deep: true
        }
    },
    computed: {
        ...mapState({
            config: (state) => state.geoFleet.config
        })
    }
}
</script>

<style scoped>
.status-colors input[type='color'] {
    width: 30px;
    height: 30px;
    border: 4px solid #fff;
    border-radius: 50%;
    overflow: hidden;
    padding: 0px;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}
</style>
