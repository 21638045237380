<template>
    <div>
        <b-tabs small pills card :navWrapperClass="'golia-pills-tabs'">
            <!-- GENERAL SETTINGS -->
            <b-tab active>
                <template #title>
                    <i class="fa-solid fa-gear"></i> {{ __('options_general') }}
                </template>

                <general-settings />
            </b-tab>

            <!-- VECHICLE LIST SETTINGS -->
            <b-tab lazy>
                <template #title>
                    <i class="fa-solid fa-list-ul"></i> {{ __('vehicle_list_options') }}
                </template>

                <vehicle-list-settings
                    v-model="vehicleList"
                    @saveOption="(option, value) => saveOption(option, value)"
                />
            </b-tab>

            <!-- MAP SETTINGS -->
            <b-tab lazy>
                <template #title>
                    <i class="fa-solid fa-map-location-dot"></i> {{ __('options_map') }}
                </template>

                <!-- VEHICLES MARKER CLUSTERING ON MAP -->
                <h5 class="section-title mt-4 mb-2">{{ __('options_map') }}</h5>

                <div class="bg-main-inner-bg p-3 rounded-3">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="map.activeVehicleClustering"
                            id="activeVehicleClustering"
                            @change="saveVehicleClustering"
                        />
                        <label class="form-check-label" for="activeVehicleClustering">
                            {{ __('vehicles_clustering') }}
                        </label>
                    </div>
                </div>

                <!-- VEHICLES MARKER GRAPHIC OPTIONS -->
                <h5 class="section-title mt-4 mb-2">{{ __('options_marker') }}</h5>

                <div class="bg-main-inner-bg p-3 rounded-3">
                    <div class="row">
                        <div class="col-lg-7">
                            <!-- MARKER SIZE (small, medium, large) -->
                            <p
                                class="font-bold text-smaller border-bottom border-dark-subtle pb-2 mb-2"
                            >
                                {{ __('size') }}
                            </p>

                            <div class="form-group d-flex align-items-center gap-2 mb-4">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        id="marker-small"
                                        v-model="map.vehicleMarkerSize"
                                        :value="[40, 54]"
                                        @change="
                                            saveOption('vehicleMarkerSize', map.vehicleMarkerSize)
                                        "
                                    />
                                    <label class="form-check-label" for="marker-small">
                                        {{ __('small') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        id="marker-medium"
                                        v-model="map.vehicleMarkerSize"
                                        :value="[52, 66]"
                                        @change="
                                            saveOption('vehicleMarkerSize', map.vehicleMarkerSize)
                                        "
                                    />
                                    <label class="form-check-label" for="marker-medium">
                                        {{ __('medium') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        id="marker-large"
                                        v-model="map.vehicleMarkerSize"
                                        :value="[60, 74]"
                                        @change="
                                            saveOption('vehicleMarkerSize', map.vehicleMarkerSize)
                                        "
                                    />
                                    <label class="form-check-label" for="marker-large">
                                        {{ __('large') }}
                                    </label>
                                </div>
                            </div>

                            <!-- MARKER FONT SIZE (small, medium, large) -->
                            <p
                                class="font-bold text-smaller border-bottom border-dark-subtle pb-2 mb-2"
                            >
                                {{ __('font_size') }}
                            </p>

                            <div class="form-group d-flex align-items-center gap-2 mb-4">
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        id="text-marker-small"
                                        v-model="map.vehicleMarkerFontSize"
                                        :value="'8px'"
                                        @change="
                                            saveOption(
                                                'vehicleMarkerFontSize',
                                                map.vehicleMarkerFontSize
                                            )
                                        "
                                    />
                                    <label class="form-check-label" for="text-marker-small">
                                        {{ __('small') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        id="text-marker-medium"
                                        v-model="map.vehicleMarkerFontSize"
                                        :value="'10px'"
                                        @change="
                                            saveOption(
                                                'vehicleMarkerFontSize',
                                                map.vehicleMarkerFontSize
                                            )
                                        "
                                    />
                                    <label class="form-check-label" for="text-marker-medium">
                                        {{ __('medium') }}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        id="text-marker-large"
                                        v-model="map.vehicleMarkerFontSize"
                                        :value="'12px'"
                                        @change="
                                            saveOption(
                                                'vehicleMarkerFontSize',
                                                map.vehicleMarkerFontSize
                                            )
                                        "
                                    />
                                    <label class="form-check-label" for="text-marker-large">
                                        {{ __('large') }}
                                    </label>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <!-- MARKER BACKGROUND COLOR -->
                                    <p
                                        class="font-bold text-smaller border-bottom border-dark-subtle pb-2 mb-2"
                                    >
                                        {{ __('background_color') }}
                                    </p>

                                    <div class="form-group mb-3">
                                        <input
                                            v-model="map.vehicleMarkerBackgroundColor"
                                            type="color"
                                            class="form-control form-control-color"
                                            @blur="
                                                saveOption(
                                                    'vehicleMarkerBackgroundColor',
                                                    map.vehicleMarkerBackgroundColor
                                                )
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="col-4">
                                    <!-- MARKER FONT COLOR -->
                                    <p
                                        class="font-bold text-smaller border-bottom border-dark-subtle pb-2 mb-2"
                                    >
                                        {{ __('font_color') }}
                                    </p>

                                    <div class="form-group mb-3">
                                        <input
                                            v-model="map.vehicleMarkerFontColor"
                                            type="color"
                                            class="form-control form-control-color"
                                            @blur="
                                                saveOption(
                                                    'vehicleMarkerFontColor',
                                                    map.vehicleMarkerFontColor
                                                )
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="col-4">
                                    <!-- MARKER OPACITY -->
                                    <p
                                        class="font-bold text-smaller border-bottom border-dark-subtle pb-2 mb-2"
                                    >
                                        {{ __('opacity') }} %
                                    </p>

                                    <div class="form-group mb-3">
                                        <input
                                            v-model="map.vehicleMarkerOpacity"
                                            type="number"
                                            min="0"
                                            max="100"
                                            step="10"
                                            class="form-control"
                                            style="font-size: 14px"
                                            @input="
                                                saveOption(
                                                    'vehicleMarkerOpacity',
                                                    map.vehicleMarkerOpacity
                                                )
                                            "
                                        />
                                    </div>
                                </div>
                            </div>

                            <!-- MARKER ADDITIONAL INFO -->
                            <p
                                class="font-bold text-smaller border-bottom border-dark-subtle pb-2 mb-2"
                            >
                                {{ __('additional_info') }}
                            </p>

                            <v-select
                                v-model="map.vehicleMarkerAdditionalData"
                                :options="markerAdditionalDataOptions"
                                :placeholder="__('select')"
                                :clearable="true"
                                :searchable="false"
                                @input="
                                    saveOption(
                                        'vehicleMarkerAdditionalData',
                                        map.vehicleMarkerAdditionalData?.value
                                    )
                                "
                            ></v-select>

                            <div class="row mt-3" v-if="map.vehicleMarkerAdditionalData">
                                <div class="col-4">
                                    <!-- ADDITIONAL INFO BACKGROUND COLOR -->
                                    <p
                                        class="font-bold text-smaller border-bottom border-dark-subtle pb-2 mb-2"
                                    >
                                        - {{ __('background_color') }}
                                    </p>

                                    <div class="form-group mb-3">
                                        <input
                                            v-model="map.vehicleAdditionalInfoBackgroundColor"
                                            type="color"
                                            class="form-control form-control-color"
                                            @blur="
                                                saveOption(
                                                    'vehicleAdditionalInfoBackgroundColor',
                                                    map.vehicleAdditionalInfoBackgroundColor
                                                )
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="col-4">
                                    <!-- ADDITIONAL INFO FONT COLOR -->
                                    <p
                                        class="font-bold text-smaller border-bottom border-dark-subtle pb-2 mb-2"
                                    >
                                        - {{ __('font_color') }}
                                    </p>

                                    <div class="form-group mb-3">
                                        <input
                                            v-model="map.vehicleAdditionalInfoFontColor"
                                            type="color"
                                            class="form-control form-control-color"
                                            @blur="
                                                saveOption(
                                                    'vehicleAdditionalInfoFontColor',
                                                    map.vehicleAdditionalInfoFontColor
                                                )
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="col-4">
                                    <!-- ADDITIONAL INFO OPACITY -->
                                    <p
                                        class="font-bold text-smaller border-bottom border-dark-subtle pb-2 mb-2"
                                    >
                                        - {{ __('opacity') }}
                                    </p>

                                    <div class="form-group mb-3">
                                        <input
                                            v-model="map.vehicleAdditionalInfoOpacity"
                                            type="number"
                                            min="0"
                                            max="100"
                                            step="10"
                                            class="form-control"
                                            style="font-size: 14px"
                                            @input="
                                                saveOption(
                                                    'vehicleAdditionalInfoOpacity',
                                                    map.vehicleAdditionalInfoOpacity
                                                )
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5" v-resized="resized">
                            <geofleet-map
                                class="geofleet-map smaller-controls rounded-3"
                                map="silica"
                                :trucks="[dummy_data.truck]"
                                :center="[
                                    dummy_data.truck.lastpoint.lat,
                                    dummy_data.truck.lastpoint.lng
                                ]"
                                :zoom="14"
                                :advanced="true"
                                :ptvToken="$env.ptv_password"
                                :extraControls="{}"
                                :noControls="true"
                                ref="settings_map"
                            >
                            </geofleet-map>
                        </div>
                    </div>
                </div>
            </b-tab>

            <!-- DRIVERS STATUS SETTINGS -->
            <b-tab lazy>
                <template #title>
                    <i class="fa-solid fa-user-gear"></i> {{ __('driver_status') }}
                </template>

                <drivers-status-settings
                    v-model="driversStatus"
                    @saveOption="(option, value) => saveOption(option, value)"
                />
            </b-tab>

            <!-- RESET SETTINGS BUTTON -->
            <template #tabs-end>
                <b-nav-item link-classes="bg-danger text-white" @click="$emit('resetSettings')">
                    <i class="fa-solid fa-trash-can"></i> {{ __('reset_settings') }}
                </b-nav-item>
            </template>
        </b-tabs>
    </div>
</template>

<script>
import L from 'leaflet'
import { mapState, mapActions } from 'vuex'
import dummyTruck from '@/dummy_data/truck.js'
import DriversStatusSettings from './DriversStatusSettings.vue'

export default {
    name: 'GeoFleetSettings',
    props: {
        companies: {
            type: [Object, Array],
            default: () => {}
        }
    },
    data() {
        return {
            loading: false,
            dummy_data: {
                truck: dummyTruck
            },
            vehicleList: {
                viewMode: 'list',
                tableFields: [
                    { value: 'status', label: this.__('status') },
                    { value: 'plate', label: this.__('plate') }
                ],
                fluidTable: false,

                vehicleIdentification: null,
                copyIdentificationToMarker: false,

                iconType: null,
                copyIconTypeToMarker: false,
                iconColor: null,
                copyIconColorToMarker: false,

                truckListItemFields: []
            },
            map: {
                activeVehicleClustering: null,
                vehicleMarkerSize: [52, 66],
                vehicleMarkerFontSize: '10px',
                vehicleMarkerFontColor: null,
                vehicleMarkerBackgroundColor: null,
                vehicleMarkerOpacity: 50,
                vehicleMarkerAdditionalData: null,
                vehicleAdditionalInfoBackgroundColor: null,
                vehicleAdditionalInfoFontColor: null,
                vehicleAdditionalInfoOpacity: 50
            },
            markerAdditionalDataOptions: [
                {
                    label: this.__('speed'),
                    value: 'speed'
                },
                {
                    label: this.__('altitude'),
                    value: 'altitude'
                },
                {
                    label: this.__('rpm_engine'),
                    value: 'rpm_engine'
                }
            ],
            analysis: {
                chart: {
                    speed: {
                        active: true
                    },
                    activity: {
                        active: true,
                        top: '79%',
                        height: '25%',
                        opposite: true
                    },
                    altitude: {
                        active: true
                    },
                    consumption: {
                        active: true,
                        format: '{value} l'
                    }
                },
                hideTimeline: false
            },
            driversStatus: {
                colors: {}
            }
        }
    },
    methods: {
        resized() {
            if (this.$refs.settings_map && this.$refs.settings_map.lmap)
                this.$refs.settings_map.lmap.invalidateSize(true)
        },
        saveOption(option, value) {
            // If value is an Object update its properties instead of the entire object
            if (typeof value === 'object' && Array.isArray(value) === false && value !== null) {
                this.$store.dispatch('saveConfig', {
                    [option]: { ...this.config[option], ...value },
                    vueInstance: this
                })
                return
            }

            this.$store.dispatch('saveConfig', { [option]: value, vueInstance: this })
        },
        saveTableFields(fields) {
            this.saveOption('vehicleListTableFields', { fields: fields })
        },
        saveVehicleClustering() {
            this.$bvModal
                .msgBoxConfirm(this.__('vehicles_clustering_warning'), {
                    title: this.__('warning'),
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: this.__('yes'),
                    cancelTitle: this.__('no'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then((value) => {
                    // Yes
                    if (value) {
                        this.saveOption('activeVehicleClustering', this.map.activeVehicleClustering)
                        location.reload()
                        // No
                    } else {
                        this.saveOption('activeVehicleClustering', this.map.activeVehicleClustering)
                    }
                })
                .catch(() => {
                    // Error
                })
        },
        resetData() {
            if (this.config) {
                // Vehicle List settings
                this.vehicleList.viewMode = this.config.vehicleListModeView || 'list'
                this.vehicleList.fluidTable = this.config.vehicleListFluidTable || false
                this.vehicleList.vehicleIdentification = this.config.vehicleIdentification || null
                this.vehicleList.copyIdentificationToMarker =
                    this.config.copyIdentificationToMarker || false
                this.vehicleList.truckListItemFields = this.config.truckListItemFields || [
                    'address',
                    'speed',
                    'fuel_battery_level',
                    'altitude'
                ]
                this.vehicleList.iconType = this.config.iconType || null
                this.vehicleList.copyIconTypeToMarker = this.config.copyIconTypeToMarker || false
                this.vehicleList.iconColor = this.config.iconColor || null
                this.vehicleList.copyIconColorToMarker = this.config.copyIconColorToMarker || false

                // Map settings
                this.map.activeVehicleClustering = this.config.activeVehicleClustering ?? true
                this.map.vehicleMarkerSize = this.config.vehicleMarkerSize ?? [52, 66]
                this.map.vehicleMarkerFontSize = this.config.vehicleMarkerFontSize ?? '10px'
                this.map.vehicleMarkerFontColor = this.config.vehicleMarkerFontColor ?? '#000000'
                this.map.vehicleMarkerBackgroundColor =
                    this.config.vehicleMarkerBackgroundColor ?? '#ffffff'
                this.map.vehicleMarkerOpacity = this.config.vehicleMarkerOpacity ?? 50
                this.map.vehicleMarkerAdditionalData = this.config.vehicleMarkerAdditionalData
                    ? {
                          value: this.config.vehicleMarkerAdditionalData,
                          label: this.__(this.config.vehicleMarkerAdditionalData)
                      }
                    : null

                this.map.vehicleAdditionalInfoBackgroundColor =
                    this.config.vehicleAdditionalInfoBackgroundColor ?? '#ffffff'
                this.map.vehicleAdditionalInfoFontColor =
                    this.config.vehicleAdditionalInfoFontColor ?? '#000000'
                this.map.vehicleAdditionalInfoOpacity =
                    this.config.vehicleAdditionalInfoOpacity ?? 50

                this.driversStatus = this.config.driversStatus || { colors: {} }

                // For every single property of this.config.chart set the relative this.analysis.chart property
                if (this.config.chart)
                    for (const [key, value] of Object.entries(this.config.chart)) {
                        if (this.analysis.chart[key]) this.analysis.chart[key] = value
                    }
            }
        },
        ...mapActions({
            logout: 'logout'
        })
    },
    created() {
        // For chart settings to work properly, we need to save the config in the store at the beginning
        if (!this.config || (this.config && !this.config.chart))
            this.$store.dispatch('saveConfig', { chart: this.analysis.chart, vueInstance: this })

        this.resetData()
    },
    watch: {
        config: function () {
            this.resetData()
        }
    },
    computed: {
        ...mapState({
            config: (state) => state.geoFleet.config
        })
    }
}
</script>

<style lang="scss">
input[type='color'].form-control.form-control-color {
    padding: 8px;
    border: 1px solid #d2d2d2;
    border-radius: 12px;
    height: calc(1.5em + 0.75rem);
    width: 100px;
}
</style>
