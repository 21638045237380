<template>
    <div>
        <!-- VEHICLE LIST MODE VIEW -->
        <h5 class="section-title mt-4 mb-2">{{ __('select_view') }}</h5>

        <div class="bg-main-inner-bg p-3 rounded-3">
            <div class="form-group d-flex align-items-center gap-2">
                <div class="form-check">
                    <input
                        class="form-check-input"
                        id="list"
                        type="radio"
                        v-model="vehicleList.viewMode"
                        :value="'list'"
                    />
                    <label class="form-check-label" for="list">{{ __('list') }}</label>
                </div>

                <div class="form-check">
                    <input
                        class="form-check-input"
                        id="table"
                        type="radio"
                        v-model="vehicleList.viewMode"
                        :value="'table'"
                    />
                    <label class="form-check-label" for="table">{{ __('table') }}</label>
                </div>
            </div>
        </div>

        <!-- VEHICLE LIST MODE VIEW (RELATED OPTIONS) -->
        <h5 class="section-title mt-4 mb-2">
            {{ __('view_options') }} "{{ __(vehicleList.viewMode) }}"
        </h5>

        <div class="bg-main-inner-bg p-3 rounded-3">
            <template v-if="vehicleList.viewMode === 'table'">
                <golia-multiselect
                    label="label"
                    track-by="value"
                    :value="tableFields"
                    :emptyLabel="__('empty_search')"
                    :placeholder="__('select_fields_placeholder') + '...'"
                    :selectLabel="__('select')"
                    :selectedLabel="__('selected')"
                    :deselectLabel="__('deselect')"
                    :options="tableFieldsOptions"
                    :show-labels="false"
                    :multiple="true"
                    :close-on-select="false"
                    :customSelectedLabel="{
                        singular: __('selected_field'),
                        plural: __('selected_fields')
                    }"
                    :outsideTagsSelected="true"
                    :customValueLabel="{
                        singular: __('selected_field'),
                        plural: __('selected_fields')
                    }"
                    id="vehicleListTableFields_Options"
                    refer="vehicleListTableFields_Options"
                    @input="saveTableFields"
                >
                </golia-multiselect>

                <hr />

                <div class="form-check">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="vehicleList.fluidTable"
                        id="fluidTable"
                        @change="saveOption('vehicleListFluidTable', vehicleList.fluidTable)"
                    />
                    <label class="form-check-label" for="fluidTable">
                        {{ __('fluid_table') }}
                    </label>
                </div>
                <small class="form-text text-tiny">
                    {{ __('fluid_table_descr') }}
                </small>
            </template>

            <template v-else>
                <b-tabs small pills card :navWrapperClass="'golia-pills-tabs inverse'">
                    <!-- Icona -->
                    <b-tab active>
                        <template #title>
                            <i class="fa-solid fa-font-awesome"></i>
                            {{ __('element_icon_section') }}
                        </template>

                        <div class="row mt-2 align-items-center">
                            <div class="col-lg-5">
                                <div class="dummy-main-panel">
                                    <truck-list-item
                                        class="truck-list-item"
                                        :truck="dummy_data.truck"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="form-group my-3">
                                    <p
                                        class="text-smaller border-bottom border-dark-subtle pb-2 mb-2"
                                    >
                                        - {{ __('element_icon_description') }}
                                    </p>

                                    <div class="row align-items-end">
                                        <div class="col-lg-6">
                                            <div class="form-group mb-3">
                                                <label class="mb-2">{{ __('element_icon') }}</label>
                                                <v-select
                                                    v-model="vehicleList.iconType"
                                                    :options="iconTypes"
                                                    :placeholder="__('select')"
                                                    :clearable="true"
                                                    :searchable="false"
                                                    @input="
                                                        saveOption('iconType', vehicleList.iconType)
                                                    "
                                                ></v-select>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-check mb-3">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    v-model="vehicleList.copyIconTypeToMarker"
                                                    id="icontype-to-marker"
                                                    @change="
                                                        saveOption(
                                                            'copyIconTypeToMarker',
                                                            vehicleList.copyIconTypeToMarker
                                                        )
                                                    "
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="icontype-to-marker"
                                                >
                                                    {{ __('apply_to_marker') }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row align-items-end">
                                        <div class="col-lg-6">
                                            <div class="form-group mb-3">
                                                <label class="mb-2">{{
                                                    __('element_icon_color')
                                                }}</label>
                                                <v-select
                                                    v-model="vehicleList.iconColor"
                                                    :options="iconColors"
                                                    :placeholder="__('select')"
                                                    :clearable="true"
                                                    :searchable="false"
                                                    @input="
                                                        saveOption(
                                                            'iconColor',
                                                            vehicleList.iconColor
                                                        )
                                                    "
                                                ></v-select>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-check mb-3">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    v-model="vehicleList.copyIconColorToMarker"
                                                    id="iconcolor-to-marker"
                                                    @change="
                                                        saveOption(
                                                            'copyIconColorToMarker',
                                                            vehicleList.copyIconColorToMarker
                                                        )
                                                    "
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="iconcolor-to-marker"
                                                >
                                                    {{ __('apply_to_marker') }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>

                    <!-- Identificazione -->
                    <b-tab>
                        <template #title>
                            <i class="fa-solid fa-id-card"></i>
                            {{ __('element_title_section') }}
                        </template>

                        <div class="row mt-2 align-items-center">
                            <div class="col-lg-5">
                                <div class="dummy-main-panel">
                                    <truck-list-item
                                        class="truck-list-item"
                                        :truck="dummy_data.truck"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="form-group my-3">
                                    <p
                                        class="text-smaller border-bottom border-dark-subtle pb-2 mb-2"
                                    >
                                        - {{ __('vehicle_identification') }}
                                    </p>

                                    <div class="form-group">
                                        <div
                                            v-for="(option, index) in vehicleIdentificationOptions"
                                            :key="index"
                                            class="form-check"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="radio"
                                                :id="option.value || 'vehicle-default'"
                                                :value="option.value"
                                                v-model="vehicleList.vehicleIdentification"
                                                @change="
                                                    saveOption(
                                                        'vehicleIdentification',
                                                        vehicleList.vehicleIdentification
                                                    )
                                                "
                                            />
                                            <label
                                                class="form-check-label"
                                                :for="option.value || 'vehicle-default'"
                                                >{{ option.label }}</label
                                            >
                                        </div>
                                    </div>

                                    <hr class="divider black thin" />

                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            v-model="vehicleList.copyIdentificationToMarker"
                                            id="identification-to-marker"
                                            @change="
                                                saveOption(
                                                    'copyIdentificationToMarker',
                                                    vehicleList.copyIdentificationToMarker
                                                )
                                            "
                                        />
                                        <label
                                            class="form-check-label"
                                            for="identification-to-marker"
                                        >
                                            {{ __('apply_to_marker') }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>

                    <!-- Informazioni del veicolo -->
                    <b-tab>
                        <template #title>
                            <i class="fa-solid fa-list-timeline"></i>
                            {{ __('element_fields_list_section') }}
                        </template>

                        <div class="row mt-2 align-items-center">
                            <div class="col-lg-5">
                                <div class="dummy-main-panel">
                                    <truck-list-item
                                        class="truck-list-item"
                                        :truck="dummy_data.truck"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="form-group my-3">
                                    <p
                                        class="text-smaller border-bottom border-dark-subtle pb-2 mb-2"
                                    >
                                        - {{ __('vehicle_fields_list') }}
                                    </p>

                                    <div class="row">
                                        <div
                                            class="col-6"
                                            v-for="(option, index) in truckListItemFieldsOptions"
                                            :key="index"
                                        >
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    :disabled="shouldCheckboxDisabled(option.value)"
                                                    :id="option.value"
                                                    type="checkbox"
                                                    :value="option.value"
                                                    v-model="vehicleList.truckListItemFields"
                                                    @change="
                                                        saveOption(
                                                            'truckListItemFields',
                                                            vehicleList.truckListItemFields
                                                        )
                                                    "
                                                />
                                                <label class="form-check-label" :for="option.value">
                                                    {{ option.label }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <b-alert
                                        class="mt-3"
                                        variant="info"
                                        show
                                        style="font-size: 14px; padding: 8px 16px"
                                    >
                                        {{ __('vehicle_fields_list_warning') }}
                                    </b-alert>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </template>
        </div>
    </div>
</template>

<script>
import TruckListItem from '@/views/geofleet/components/MainPanel/TruckListItem.vue'
import dummyTruck from '@/dummy_data/truck.js'
import { mapState } from 'vuex'

export default {
    name: 'VehicleListSettings',
    props: {
        value: Object
    },
    data() {
        return {
            vehicleList: this.value,
            tableFieldsOptions: [
                { value: 'status', label: this.__('status') },
                { value: 'plate', label: this.__('plate') },
                { value: 'custom_id', label: this.__('custom_id') },
                { value: 'activity', label: this.__('activity') },
                { value: 'codriver', label: this.__('codriver') },
                { value: 'speed', label: this.__('speed') },
                { value: 'tacho_speed', label: this.__('tacho_speed') },
                { value: 'heading', label: this.__('heading') },
                { value: 'altitude', label: this.__('altitude') },
                { value: 'battery_level', label: this.__('battery_level') },
                { value: 'fuel_level', label: this.__('fuel_level') },
                { value: 'rpm_engine', label: this.__('rpm_engine') },
                { value: 'ignition', label: this.__('ignition') },
                { value: 'odometer', label: this.__('odometer') },
                { value: 'gps_odometer', label: this.__('odometer_gps') },
                { value: 'daily_distance', label: this.__('daily_distance') },
                { value: 'gnss_status', label: this.__('gnss_status') },
                { value: 'gsm_signal', label: this.__('gsm_signal') },
                { value: 'address', label: this.__('address') },
                { value: 'alerts', label: this.__('alerts') }
            ],
            vehicleIdentificationOptions: [
                { value: null, label: this.__('default') },
                { value: 'plate', label: this.__('plate') },
                { value: 'customId', label: this.__('custom_id') },
                {
                    value: 'driver',
                    label: this.__('name') + ' ' + this.__('surname') + ' - ' + this.__('driver')
                }
            ],
            iconTypes: [
                {
                    label: this.__('vehicle'),
                    value: 'truck'
                },
                {
                    label: this.__('driver_activity'),
                    value: 'driver_activity'
                }
            ],
            iconColors: [
                {
                    label: this.__('truck_status'),
                    value: 'truck_status'
                },
                {
                    label: this.__('driver_activity'),
                    value: 'driver_activity'
                },
                {
                    label: this.__('driver_status'),
                    value: 'driver_status'
                }
            ],
            truckListItemFieldsOptions: [
                { value: 'address', label: this.__('address') },
                { value: 'speed', label: this.__('speed') },
                { value: 'tacho_speed', label: this.__('tacho_speed') },
                { value: 'heading', label: this.__('heading') },
                { value: 'altitude', label: this.__('altitude') },
                {
                    value: 'fuel_battery_level',
                    label: this.__('fuel') + ' / ' + this.__('battery')
                },
                { value: 'rpm_engine', label: this.__('rpm_engine') },
                { value: 'daily_distance', label: this.__('daily_distance') },
                { value: 'gnss_status', label: this.__('gnss_status') },
                { value: 'gsm_signal', label: this.__('gsm_signal') }
            ],
            dummy_data: {
                truck: dummyTruck
            }
        }
    },
    components: {
        TruckListItem
    },
    methods: {
        saveTableFields(fields) {
            this.$emit('saveOption', 'vehicleListTableFields', { fields: fields })
        },
        saveOption(option, value) {
            this.$emit('saveOption', option, value)
        },
        shouldCheckboxDisabled(value) {
            if (value === 'address') return false

            return (
                this.truckListItemFieldsCount >= 3 &&
                !this.vehicleList.truckListItemFields.includes(value)
            )
        }
    },
    watch: {
        value: {
            handler(value) {
                this.vehicleList = value
            },
            deep: true
        },
        vehicleList: {
            handler(value) {
                this.$emit('input', value)
            },
            deep: true
        },
        'vehicleList.viewMode': function (val) {
            this.saveOption('vehicleListModeView', val)

            if (val === 'table' && !this.config.vehicleListTableFields) {
                this.saveOption('vehicleListTableFields', { fields: this.tableFields })
            }
        }
    },
    computed: {
        tableFields() {
            return this.config?.vehicleListTableFields?.fields || this.vehicleList.tableFields
        },
        truckListItemFieldsCount() {
            let fields = this.vehicleList.truckListItemFields.filter(
                (field) =>
                    field !== 'address' && field !== 'fuel_level' && field !== 'battery_level'
            )

            return fields.length
        },
        ...mapState({
            config: (state) => state.geoFleet.config
        })
    }
}
</script>

<style lang="scss">
.dummy-main-panel {
    --side-panels-security-width: 380px;

    max-width: var(--side-panels-security-width);
    margin: 0 auto;

    .truck-list-item {
        background: var(--main-bg-color);
        border: 1px solid #ccc;
    }
}
</style>
